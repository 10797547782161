.faqContainer {
    padding-top: 10px;
}

.faqContainer > div {
    margin: 25px;
}

.faqContainer > div > h4 {
    padding-left: 5px;
}

.faqContainer a {
    color: #2FD5E7;
    text-decoration: none;
}

.faqContainer a:active {
    color: #2FD5E7;
    text-decoration-color: #0056b3 !important;
    text-decoration: underline;
}

.faqContainer a:hover {
    color: #2FD5E7;
    text-decoration-color: #0056b3 !important;
    text-decoration: underline;
}

.faqContainer a:visited {
    color: #2FD5E7;
}

.faqPrimary {
    background-color: rgba(230, 246, 250, 0.062);
    border-radius: 10px;
    padding: 12px;
}

.faqSecondary {
    /* decided not to alternate? */
    background-color: rgba(230, 246, 250, 0.062);
    border-radius: 10px;
    padding: 12px;
}

.faqItem {
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

summary {
    font-size: 1.25rem;
    font-weight: 600;
    background-color: rgba(230,246,250,.062);
    color: #2FD5E7;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;
    position: relative;
  }
  details > summary::after {
    position: absolute;
    content: "+";
    right: 20px;
  }
  details[open] > summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }
  details > summary::-webkit-details-marker {
    display: none;
  }
  details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
  }
  @keyframes sweep {
    0%    {opacity: 0; margin-top: -10px}
    100%  {opacity: 1; margin-top: 0px}
  }